import React from 'react'
import { Link } from 'gatsby'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import vimeo from '../img/social/vimeo.svg'
import logo from '../img/logo.png'

const Footer = class extends React.Component {
  
  render() {
    return (
      <footer
      style = {{
        padding: '5em 20px'
      }}
      className="footer"
      >
        <div className="content">
          <div className="container">
            <div className="columns">
              <div className="column is-4">
                <img src={logo} alt="Dr. Andre Buthe" />
              </div>
              <div className="column is-8">
                <section style={{
                  // display: 'flex',
                  // alignItems: 'center',
                  // justifyContent: 'center',
                  // height: '100%',
                  // flexDirection: `column`
                }}>
                  <h4>Contact</h4>
                  <address>
                    Tel: +44 (0)1672 552882<br />
                    Email: <a href="mailto:office@andrebuthe.com">office@andrebuthe.com</a>
                  </address>
                  {/* <p>
                    Dr Andre Buthe Equine Clinic Ltd.<br />
                    The Mill House<br />
                    Poulton Mill<br />
                    Poulton<br />
                    Marlborough<br />
                    Wiltshire<br />
                    SN8 2LN
                  </p>
                  <p><strong>Office hours 9-12am 2-5pm</strong></p> */}
                </section>
              </div>

            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
